import Swiper from 'swiper/bundle';

export const initAboutSlider = () => {
    new Swiper('[data-about-slider]', {
        loop: true,
        slidesPerView: 1,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
    });
};

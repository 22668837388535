export const initToggleSearch = () => {
    // VARS
    const searchToggles = document.querySelectorAll('[data-search-toggle]');
    const searchForm = document.querySelector('[data-search-form]');
    const header = document.querySelector('[data-header]');
    if (!header) return;

    // LISTENERS
    searchToggles.forEach(function (searchToggle) {
        searchToggle.addEventListener('click', onClickToggleHandler, false);
    });

    document.addEventListener('click', (e) => {
        if (!e.target.closest('[data-search-bar]')) {
            close();
        }
    });

    document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape' && searchForm.classList.contains('show')) {
            close();
        }
    });

    // HANDLERS
    function onClickToggleHandler() {
        const searchForm = this.nextElementSibling;
        searchForm.classList.toggle('show');
    }

    function close() {
        searchForm.classList.remove('show');
    }
};

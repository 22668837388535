export const initPaymentPopup = () => {
    // VARS
    const popupTrigger = document.querySelectorAll('[data-popup]');
    const popup = document.querySelector('.popup');
    const popupCloseBtn = document.querySelector('[data-close]');
    if (!popupTrigger.length) return;

    // LISTENERS
    popupTrigger.forEach((btn) => {
        btn.addEventListener('click', handleOnClickOpenPopup, false);
    });

    popupCloseBtn.addEventListener('click', handleOnClickClosePopup, false);

    document.addEventListener('keydown', handleOnKeydownEscape, false);

    // HANDLERS
    function handleOnClickOpenPopup() {
        openPopup();
    }

    function handleOnClickClosePopup() {
        closePopup();
        resetForm();
    }

    function handleOnKeydownEscape(event) {
        if (event.key === 'Escape' && popup.classList.contains('show')) {
            closePopup();
        }
    }

    // FUNCTIONS
    function openPopup() {
        popup.classList.add('show');
        popup.classList.remove('hide');
        disableScroll();
    }

    function closePopup() {
        popup.classList.add('hide');
        popup.classList.remove('show');
        enableScroll();
    }

    function resetForm() {
        const errorContainer = document.querySelector('[data-inventory-error]');
        const fields = document.querySelectorAll('[data-field]');
        const errors = document.querySelectorAll('[data-field-error]');

        errorContainer.classList.add('hide');

        [...errors].forEach((error) => {
            error.remove();
        });

        [...fields].forEach((field) => {
            field.value = '';
        });
    }
};

export const initLoadMoreBooks = () => {
    // VARS
    const booksContainer = document.querySelector('[data-books]');
    if (!booksContainer) return;
    const books = Array.from(booksContainer.querySelectorAll('[data-book]'));
    const loadMore = document.querySelector('[data-load-more]');

    let maxItems = 4;
    let loadItems = 8;

    const hiddenClass = 'hiddenStyle';
    const hiddenItems = Array.from(document.querySelectorAll('.hiddenStyle'));

    // LISTENERS

    // HANDLERS

    books.forEach(function (item, index) {
        if (index > maxItems - 1) {
            item.classList.add(hiddenClass);
        }
    });

    loadMore.addEventListener('click', function () {
        [].forEach.call(document.querySelectorAll('.' + hiddenClass), function (item, index) {
            if (index < loadItems) {
                item.classList.remove(hiddenClass);
            }

            if (document.querySelectorAll('.' + hiddenClass).length === 0) {
                loadMore.style.display = 'none';
            }
        });
    });

    // FUNCTIONS
};

export const initSearch = () => {
    // Vars
    const header = document.querySelector('[data-header]');
    if (!header) return;

    const inputSearchs = document.querySelectorAll('[data-search-input]');

    // Listeners
    inputSearchs.forEach((inputSearch) => {
        const header = document.querySelector('[data-header]');
        if (!header) return;

        let base_url = header.getAttribute('data-url');
        const priceUrl = header.getAttribute('data-price-url');

        if (!base_url && !pricePage) return;

        let resultResult = inputSearch.nextElementSibling;

        inputSearch.addEventListener('keyup', async (event) => {
            event.preventDefault();
            if (!inputSearch.value.trim()) {
                return;
            }

            const searchText = inputSearch.value;
            const response = await fetch(`${base_url}?q=${searchText}`, {
                method: 'GET',
            });

            if (!response.ok) {
                return;
            }

            const data = await response.json();

            addBook(data);

            function addBook(data) {
                removeAllChildNodes(resultResult);

                if (data.length) {
                    resultResult.style.display = 'block';
                    data.forEach((element) => {
                        resultResult.insertAdjacentHTML(
                            'beforeend',
                            `<div class="search-book">
                                <a href="${priceUrl}" class="search-book_wrapper">
                                    <div class="search-book_img">
                                        <img src='${element.image} '>
                                    </div>
                                    <h4 class="search-book_title ">
                                        ${element.title}
                                    </h4>
                                </a>
                                <a href="${priceUrl}" class="search-book_link">
                                    Read Now
                                </a>
                            </div>
                        `,
                        );
                    });
                } else if (!data.length) {
                    resultResult.style.display = 'block';
                    resultResult.insertAdjacentHTML(
                        'beforeend',
                        `<div class="search-book">
                            <h4 class="search-book_title ">
                              ${searchText} Not found.
                            </h4>
                        </div>
                    `,
                    );
                }

                if (!inputSearch.value.length) {
                    resultResult.style.display = 'none';
                }
            }

            function removeAllChildNodes(parent) {
                while (parent.firstChild) {
                    parent.removeChild(parent.firstChild);
                }
            }
        });
    });
};
